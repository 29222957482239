define(['app', '$console'], (app, $console) => {

  const subscribeAndSave = () => {
    const DATA_SELECTED = 'data-selected';
    const DATA_UPSELL_MESSAGE = 'data-upsell-message';
    const component = {};

    component.config = {
      selectors: {
        oneTimePurchaseRadioLabel: '.subscribeAndSave_oneTimePurchase_radio_label',
        subscriptionRadioLabel: '.subscribeAndSave_subscription_radio_label',
        subscriptionContainer: '.subscribeAndSave_subscription_container',
        radio: '.subscribeAndSave_radio',
        upsellMessage: '.upsellMessage',
        upsellMessageContainer: '.subscribeAndSave_subscription_listItem',
        subscribeFocusStatus: '.subscribeFocusStatus',
        subscribeAndSaveRadioSubscribe: '#purchaseType-subscription',
        otpPrice: '.subscribeAndSave_oneTimePurchase_price',
        subscribeAndSavePrice: '.subscribeAndSave_subscription_price',
        subOnlyProduct: '.product-15229547 || .product-15229549' ,
      },
      radioModeSelectors: {
        radioOption: '.subscribeAndSave_radio_option'
      },
      classes: {
        subscriptionContainerShow: 'subscribeAndSave_subscription_container_show',
        radioModeShow: 'subscribeAndSave_radio_option-selected'
      },
      subscribeChannels: {
        showSubscribeAndSaveContracts: 'subscribeAndSaveContracts/show',
        subscribeAndSaveContractsChange: 'subscribeAndSave/contractsChange',
        upsellMessage: 'productPrice/upsellMessage',
        subscribeAndSaveProductInBasketTabsChanges: 'subscribeAndSaveProductInBasket/tabChanges',
        price: 'productPrice/newPrice',
        subscribeAndSaveContractsContractIdChange: 'subscribeAndSaveContracts/changeContractId',
        subscribeAndSaveContractsShow: 'subscribeAndSaveContracts/update',
        subscribeAndSaveDefault: 'subscribeAndSave/subscribeDefault'
      },
      dataAttributes: {
        type: 'data-type',
        purchaseType: 'data-purchase-type',
        productId: 'data-product-id',
      }
    };

    component.init = (element) => {
      component.element = element;
      component.type = element.getAttribute(component.config.dataAttributes.type) || 'default';
      component.oneTimePurchaseRadioLabel = element.querySelector(component.config.selectors.oneTimePurchaseRadioLabel);
      component.subscriptionRadioLabel = element.querySelector(component.config.selectors.subscriptionRadioLabel);
      component.subscriptionContainer = element.querySelector(component.config.selectors.subscriptionContainer);
      component.upsellMessage = element.querySelector(component.config.selectors.upsellMessage);
      component.upsellMessageContainer = element.querySelector(component.config.selectors.upsellMessageContainer);
      component.subscribeFocusStatus = element.querySelector(component.config.selectors.subscribeFocusStatus);
      component.subscribeAndSaveRadioSubscribe = element.querySelector(component.config.selectors.subscribeAndSaveRadioSubscribe);
      component.radios = element.querySelectorAll(component.config.selectors.radio);

      if(component.type === 'radio') {
        component.productId = element.getAttribute(component.config.dataAttributes.productId);
        component.radioOptions = element.querySelectorAll(component.config.radioModeSelectors.radioOption);
        component.bindRadioMode();
      } else {
        component.bind();
      }

      component.subscribeToChannels();

      component.subscriptionOnlyProduct();

      if(component.element.getAttribute('data-auto-select-subs') != null && component.element.getAttribute('data-auto-select-subs') === 'true') {
        component.subscriptionRadioLabelClick()
      }
    };

    component.bind = () => {
      component.radios.forEach(radio => radio.addEventListener('click', component.radioClicked));
    };

    component.subscribeDefault = () => {
      if (component.element.getAttribute('data-focus-status') != null && component.element.getAttribute('data-focus-status').toLowerCase() === 'subscription') {
        component.subscribeAndSaveRadioSubscribe.click();
      }
    };

    component.bindRadioMode = () => {
      component.radioOptions.forEach(radio => radio.addEventListener('click', component.radioClicked));
      component.radios.forEach(radio => radio.addEventListener('change', component.radioClicked));
    };

    component.radioClicked = (event) => {
      event.target.value === 'oneTimePurchase' || (event.target instanceof Element && event.target.getAttribute(component.config.dataAttributes.purchaseType) === 'oneTimePurchase') ?
        component.oneTimePurchaseRadioLabelClick() : component.subscriptionRadioLabelClick();
      if(component.type !== 'radio') {
        event.preventDefault();
      }
    };

    component.oneTimePurchaseRadioLabelClick = () => {
      component.oneTimePurchaseRadioLabel.setAttribute(DATA_SELECTED, 'true');
      component.subscriptionRadioLabel.setAttribute(DATA_SELECTED, 'false');
      component.element.setAttribute('data-focus-status', 'oneTimePurchase');

      if(component.type === 'radio') {
        component.oneTimePurchaseRadioLabel.classList.add(component.config.classes.radioModeShow);
        component.subscriptionRadioLabel.classList.contains(component.config.classes.radioModeShow) && component.subscriptionRadioLabel.classList.remove(component.config.classes.radioModeShow);
      }

      if (component.subscriptionContainer.classList.contains(component.config.classes.subscriptionContainerShow)) {
        component.subscriptionContainer.classList.remove(component.config.classes.subscriptionContainerShow);
      }

      app.publish(component.config.subscribeChannels.showSubscribeAndSaveContracts, false);
      app.publish(component.config.subscribeChannels.subscribeAndSaveProductInBasketTabsChanges, false);

    };

    component.subscriptionRadioLabelClick = () => {
      component.oneTimePurchaseRadioLabel.setAttribute(DATA_SELECTED, 'false');
      component.subscriptionRadioLabel.setAttribute(DATA_SELECTED, 'true');
      component.element.setAttribute('data-focus-status', 'subscription');

      if(component.type === 'radio') {
        component.oneTimePurchaseRadioLabel.classList.contains(component.config.classes.radioModeShow) && component.oneTimePurchaseRadioLabel.classList.remove(component.config.classes.radioModeShow);
        component.subscriptionRadioLabel.classList.add(component.config.classes.radioModeShow);
      }

      if (!component.subscriptionContainer.classList.contains(component.config.classes.subscriptionContainerShow)) {
        component.subscriptionContainer.classList.add(component.config.classes.subscriptionContainerShow);
      }

      app.publish(component.config.subscribeChannels.showSubscribeAndSaveContracts, true);
      app.publish(component.config.subscribeChannels.subscribeAndSaveProductInBasketTabsChanges, true);
    };


    component.updateSubscribeAndSaveContractsState = () => {
      app.publish(component.config.subscribeChannels.showSubscribeAndSaveContracts,
        component.subscriptionRadioLabel.getAttribute(DATA_SELECTED) === 'true')
    };

    component.updateUpsellMessagePropertyKey = (upsellMessage) => {
      if (upsellMessage) {
        component.upsellMessage.innerHTML = upsellMessage;
        component.upsellMessageContainer.setAttribute(DATA_UPSELL_MESSAGE, 'true');
      } else {
        component.upsellMessageContainer.setAttribute(DATA_UPSELL_MESSAGE, 'false');
      }
    };

    component.updatePrices = (id, isContractId) => {
      !isContractId && (component.productId = id)
      const productId = isContractId ? component.productId : id
      const url = new URL(`${window.location.origin}/${productId}.subscribeAndSavePrice`)
      isContractId && url.searchParams.set('subscriptionContractId', id)

      app.ajax.get({
        url: url.toString(),
        success: component.updatePriceElements,
        error: (res) => $console.error('Failed to update S&S prices productId: ', productId, ' contractId: ', isContractId ? id : 'n/a', res)
      })
    }

    component.updatePriceElements = (data) => {
      const newPriceData = JSON.parse(data);
      const otpPriceEle = component.element.querySelector(component.config.selectors.otpPrice)
      const subscribeAndSavePriceEle = component.element.querySelector(component.config.selectors.subscribeAndSavePrice)

      otpPriceEle && newPriceData && newPriceData.basePrice && (otpPriceEle.innerHTML = newPriceData.basePrice)
      subscribeAndSavePriceEle && newPriceData && newPriceData.subscriptionPrice && (subscribeAndSavePriceEle.innerHTML = newPriceData.subscriptionPrice)
    }

    component.updateOtpPrice = (productId) => component.updatePrices(productId, false)
    component.updateSubscribeAndSavePrice = (contractId) => contractId && component.updatePrices(contractId, true)

    component.subscribeToChannels = () => {
      app.clear(component.config.subscribeChannels.subscribeAndSaveContractsChange);
      app.clear(component.config.subscribeChannels.upsellMessage);
      app.subscribe(component.config.subscribeChannels.subscribeAndSaveContractsChange, component.updateSubscribeAndSaveContractsState);
      app.subscribe(component.config.subscribeChannels.upsellMessage, component.updateUpsellMessagePropertyKey);
      app.subscribe(component.config.subscribeChannels.subscribeAndSaveDefault, component.subscribeDefault);

      if (component.type === 'radio') {
        app.subscribe(component.config.subscribeChannels.price, component.updateOtpPrice);
        app.subscribe(component.config.subscribeChannels.subscribeAndSaveContractsContractIdChange, component.updateSubscribeAndSavePrice);
      }
    };

    const subsRadio =  document.querySelector('.product-15229547') || document.querySelector ('.product-15229554') || document.querySelector('.product-15229551') || document.querySelector('.product-15229550') || document.querySelector('.product-15229552') || document.querySelector('.product-15229549');

    component.subscriptionOnlyProduct = () => {
      if (subsRadio) {
        document.getElementById('purchaseType-subscription').click();
      }
    }

    return component;
  };

  return subscribeAndSave;
});
